import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";

const Plans = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col">
      <NavBar />
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="rounded-lg shadow-lg bg-white p-6 space-y-6 border border-gray-200 dark:border-gray-700">
          <div className="mt-200">
            <h1 className="text-center text-3xl font-bold">{t(`plans.title`)}</h1>
            <p className="text-zinc-500 dark:text-zinc-400 mt-10">
              {t(`plans.intro`)}
            </p> 
            <h2 className="text-xl font-bold mt-10">{t(`plans.paymentTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400 mb-80">
              {t(`plans.payment`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
