import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./components/ErrorHandler/ErrorComponents/ErrorPage";
import LandingPage from "./components/Landing";
import ContactPage from "./components/Contact";
import Plans from "./components/Plans";
import LoadingPage from "./components/Loader";
import BundleListViewPage from "./components/BundleListView/BundleListViewPage";
import LoginPage from "./components/Login";

import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import createRefresh from "react-auth-kit/createRefresh";
import BundleEditorPage from "./components/BundleEditor";
import BundleViewPage from "./components/BundleView";

import "./i18n";
import "./index.css";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import { ApiClientImpl } from "./clients/ApiClientImpl";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const apiClient = new ApiClientImpl();

const refresh = createRefresh({
  interval: 5, // The time in sec to refresh the Access token,
  refreshApiCallback: async (param) => {
    const { credential, error} = await apiClient.renovate(`Bearer ${param.refreshToken}`);
    if (!error && credential) {
      return {
        isSuccess: true,
        newAuthToken: credential.token,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 60,
      };
    } else {
      console.error("There was a problem with refreshing", error?.detail);
      return {
        isSuccess: false,
        newAuthToken: "",
      };
    }
  },
});

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
  refresh: refresh,
});

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/pricing",
        element: <Plans />,
      },
      {
        path: "/login",
        element: <LoginPage apiClient={apiClient} />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/store",
        element: <BundleViewPage apiClient={apiClient} />,
      },
      {
        element: <AuthOutlet fallbackPath="/login" />,
        children: [
          {
            path: "/explore",
            element: <BundleListViewPage apiClient={apiClient} />,
          },
          {
            path: "/bundle/create",
            element: <BundleEditorPage apiClient={apiClient} />,
          },
          {
            path: "/bundle/:slug/edit",
            element: <BundleEditorPage apiClient={apiClient} />,
          },
        ],
      },
    ],
  },
]);

root.render(
  <AuthProvider store={store}>
    <RouterProvider router={router} fallbackElement={<LoadingPage />} />
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
